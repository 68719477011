<template>
  <div id="buildin" style="font-family: 'Montserrat'; sans-serif;">
    <v-row>
      <v-col cols="12"  lg="3" xl="3">
    <Filtros/>
      </v-col>
      <v-col cols="12" xl="9" lg="9" style="float:left;">
    <ResultadosList />
    </v-col></v-row>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Filtros from "../components/resultados/Filtros.vue"

import ResultadosList from "../components/resultados/ResultadosList.vue"

export default {
  name: "Resultados",
  components: { 
    DatePicker,
    Filtros,
    ResultadosList
    },
};
</script>
<style >
</style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap");
@media (max-width: 600px) {
}
@media (max-width: 1024px) {
  v-col .div {
    min-height: 295px;
  }
}
</style>

